import React, {Component} from 'react';
import { Link, graphql, push } from 'gatsby';
import slug from 'slug';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import encode from '../utils/'
import Layout from '../components/layout';

import callToActionBackground from '../images/callto/callto-bg.jpg';
import aboutExpert from '../images/about/1.jpg';
import counterBackground from '../images/resources/fact-counter-bg.jpg';

class IndexPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      form: {},
    };
  }
  handleChange = (e) => {
    const { form } = this.state;
    this.setState({
      form: { ...form, ...{ [e.target.name]: e.target.value } },
    });
  }

  handleSubmit = (e) => {
    const { form } = this.state;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'appointment', ...form }),
    })
      .then(() => push('/success'))
      .catch(error => window.console.log(error));

    window.console.log('Form data', form);
    e.preventDefault();
  }

  render() {
    const {
      data:
       {
         allServicesJson: { edges: services },
         allSliderJson: { edges: slider },
       },
    } = this.props;
return (
    <Layout>
      <section className="rev_slider_wrapper">
        <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
          {/* Indicators */}
          <ol className="carousel-indicators">
            <li data-target="#carousel-example-generic" data-slide-to={0} className="active" />
            <li data-target="#carousel-example-generic" data-slide-to={1} />
            <li data-target="#carousel-example-generic" data-slide-to={2} />
          </ol>
          {/* Wrapper for slides */}
          <div className="carousel-inner" role="listbox">
            {
                    slider.map(slide => (
                      <div key={slide.node.image.id} className={`item ${slide.node.isActive && 'active'}`}>
                        <Img
                          src={slide.node.image.childImageSharp.sizes.src}
                          sizes={slide.node.image.childImageSharp.sizes}
                          className="img-fluid"
                          alt="Hamilton Wireless"
                        />
                        <div className="carousel-caption text-left animated delay-1s fadeInUp">
                          <div className="slide-content-box">
                            <div dangerouslySetInnerHTML={{ __html: slide.node.caption }} />
                            <div className="button  hidden-sm hidden-xs">
                              <Link className="thm-btn bg-2" to="#book-appointment">Book Appointment</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
          </div>
          {/* Controls */}
          <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
            <span className="glyphicon glyphicon-chevron-left" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
            <span className="glyphicon glyphicon-chevron-right" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
        </div>

      </section>
      <section className="categories-area" style={{ backgroundImage: `url(${callToActionBackground})` }}>
        <div className="container">
          <div className="sec-title text-center">
            <h1>Services</h1>
          </div>
          <div className="row">
            {/* Start single item */}
            {
                    services.map(service => (
                      <div key={service.node.name} className="col-md-4 col-sm-6 col-xs-12">
                        <Link to={`/services/${slug(_.lowerCase(service.node.name))}`}>
                          <div className="single-item">
                            <div className="icon-holder">
                              <div className="icon-box">
                                <div className="icon">
                                  <span className={service.node.icon} />
                                </div>
                              </div>
                            </div>
                            <div className="text-holder">
                              <h5>{service.node.name}</h5>
                              <p>
                                {service.node.shortDescription}
                                ...
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  }
            {/* End single item */}
          </div>
        </div>
      </section>
      <section className="about-us-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-content">
                <div className="sec-title-two">
                  <h1>About Us</h1>
                  <span className="border" />
                </div>
                <ul>
                  <li>
                    <div className="img-holder">
                      <img src={aboutExpert} alt="Awesome" />
                    </div>
                  </li>
                </ul>
                <div className="text-holder">
                  <p>Our commitment to bring professionalism, good service &amp; trust to the Phone repair service &amp; maintenance business. We take immense pride in sending some of the most of professional technicians to yours phone to fix things that aren't workings. </p>
                  <h3>
Save time, Save money, With Quality Phone Repair Service,
                    {' '}
                    <span>Purchase - Hamilton Wireless</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="choose-us-content">
                <div className="sec-title-two">
                  <h1>Why Choose Us</h1>
                  <span className="border" />
                </div>
                <ul>
                  <li>
                    <div className="iocn-holder">
                      <span className="flaticon-tool" />
                    </div>
                    <div className="text-holder">
                      <h4>Free Diagnostics</h4>
                      <p>Hamilton Wireless is a quick and easy way of checking the set up on your Mobile Phones, Desktop &amp; Laptop, Acessories and ect... We done its free of cost.</p>
                    </div>
                  </li>
                  <li>
                    <div className="iocn-holder">
                      <span className="flaticon-wrench" />
                    </div>
                    <div className="text-holder">
                      <h4>Quick Repair Process</h4>
                      <p>The repair process is fast and convenient &amp; our expert teams of Hamilton Wireless repair, If you see a phone symbol in the top left corner of the screen.</p>
                    </div>
                  </li>
                  <li>
                    <div className="iocn-holder">
                      <span className="flaticon-customer-support" />
                    </div>
                    <div className="text-holder">
                      <h4>Telephonic Customer Support</h4>
                      <p>Tech support is one of the best services in the Hamilton Wireless. telephonic tech support providing quality services at anytime, anywhere in the world.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fact-counter-area" style={{ backgroundImage: `url(${counterBackground})` }}>
        <div className="container">
          <div className="row">
            {/* Start single item */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-item text-center">
                <h1><span className="timer" data-from={1} data-to={2456} data-speed={5000} data-refresh-interval={50}>2456</span></h1>
                <span className="border" />
                <h3>Mobiles Repaired</h3>
              </div>
            </div>
            {/* End single item */}
            {/* Start single item */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-item text-center">
                <h1>
                  <span className="timer" data-from={1} data-to={146} data-speed={5000} data-refresh-interval={50}>1000</span>
                  <i className="fa fa-plus" aria-hidden="true" />
                </h1>
                <span className="border" />
                <h3>Macbook Repaired</h3>
              </div>
            </div>
            {/* End single item */}
            {/* Start single item */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-item text-center">
                <h1>
                  <span className="timer" data-from={1} data-to={853} data-speed={5000} data-refresh-interval={50}>853</span>
                  <i className="fa fa-plus" aria-hidden="true" />
                </h1>
                <span className="border" />
                <h3>Happy Customers</h3>
              </div>
            </div>
            {/* End single item */}
            {/* Start single item */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-item text-center">
                <h1><span className="timer" data-from={1} data-to={2381} data-speed={5000} data-refresh-interval={50}>2381</span></h1>
                <span className="border" />
                <h3>Desktop Repaired</h3>
              </div>
            </div>
            {/* End single item */}
          </div>
        </div>
      </section>
      <section id="book-appointment" className="appoinment-area">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="appoinment">
                <div className="sec-title-two">
                  <h1>Make an Appoinment</h1>
                  <span className="border" />
                </div>
                <form method="post" action="/success"  className="appoinment-form" name="appointment" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="form-name" value="appointment" />
                  <div className="row margin">
                    <div className="col-md-6 pd">
                      <div className="input-box">
                        <input required onChange={this.handleChange} type="text" name="name" placeholder="Name*" />
                      </div>
                    </div>
                    <div className="col-md-6 pd">
                      <div className="input-box">
                        <input required type="text" onChange={this.handleChange} name="mail" placeholder="Email*" />
                      </div>
                    </div>
                  </div>
                  <div className="row margin">
                    <div className="col-md-6 pd">
                      <div className="input-box">
                        <input type="text" onChange={this.handleChange} name="phone" placeholder="Phone*" />
                      </div>
                    </div>
                    <div className="col-md-6 pd">
                      <div className="input-box">
                        <input type="datetime-local" onChange={this.handleChange} name="date" placeholder="Date" id="datepicker" />
                      </div>
                    </div>
                  </div>
                  <div className="row margin">
                    <div className="col-md-12 pd">
                      <div className="input-box">
                        <input required type="text" onChange={this.handleChange} name="subject" placeholder="Subject*" />
                      </div>
                    </div>
                  </div>
                  <div className="row margin">
                    <div className="col-md-12 pd">
                      <div className="input-box">
                        <textarea required name="message" onChange={this.handleChange} placeholder="Message..." defaultValue="" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button className="thm-btn bg-1" type="submit">Send Now</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>);
  }
  };
IndexPage.propTypes = {
  data: PropTypes.shape(PropTypes.object),
};
IndexPage.defaultProps = {
  data: {},
};
export default IndexPage;

export const pageQuery = graphql`
query indexPage{
  allServicesJson{
    edges{
      node{
        name
        icon
        shortDescription
        description
      }
    }
  }
  allSliderJson{
    edges{
      node{
        isActive
          caption
          image{
            childImageSharp{
              id
              sizes( maxWidth: 1500 ) {
                ...GatsbyImageSharpSizes
              }
            }
          }
      }
    }
  }
}
`;
